<template>
  <div>
    <section v-if="loaded" class="bg-white dark:bg-gray-900 min-h-screen">
      <div class="flex justify-end pt-5">
        <label for="red-toggle" class="inline-flex relative items-center mr-5 cursor-pointer">
          <input @change="changeThem" type="checkbox" value="" id="red-toggle" class="sr-only peer" checked>
          <div
            class="w-11 h-6 bg-gray-700 rounded-full peer peer-focus:ring-0 peer-focus:ring-white dark:peer-focus:ring-white dark:bg-white peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-orange-500 after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-white-600">
          </div>
          <span class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Darkmode</span>
        </label>
      </div>

      <div>
        <div :class="['py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6', table == 'wish' ? 'max-w-none' : '']">
          <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
            <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
              {{ this.$store.state.guild.guild?.name }}</h2>
            <p class="font-light text-gray-500 lg:mb-16 sm:text-xl dark:text-gray-400">
              {{ this.$store.state.guild.guild?.realm.name }}
            </p>
          </div>
          <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
            <ul class="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab"
              data-tabs-toggle="#myTabContent" role="tablist">
              <li @click="table = true;" class="mr-2" role="presentation">
                <button
                  :class="['inline-block p-4 rounded-t-lg border-b-2 dark:text-white', { 'border-blue-600': table === true }]"
                  id="profile-tab" data-tabs-target="#profile" type="button" role="tab" aria-controls="profile"
                  aria-selected="false">Table</button>
              </li>
              <li @click="table = false;" class="mr-2" role="presentation">
                <button
                  :class="['inline-block p-4 rounded-t-lg border-b-2 dark:text-white', { 'border-blue-600': table === false }]"
                  id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard"
                  aria-selected="false">Cards</button>
              </li>
              <li @click="table = 'wish';" class="mr-2" role="presentation">
                <button
                  :class="['inline-block p-4 rounded-t-lg border-b-2 dark:text-white', { 'border-blue-600': table == 'wish' }]"
                  id="dashboard-tab" data-tabs-target="#dashboard" type="button" role="tab" aria-controls="dashboard"
                  aria-selected="false">Wishlist</button>
              </li>
            </ul>
          </div>

          <div v-if="table === true">
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th @click="byName" scope="col" class="py-3 px-6 cursor-pointer">
                      Name
                    </th>
                    <th @click="sortClass" scope="col" class="py-3 px-6 cursor-pointer">
                      Class
                    </th>
                    <th @click="sortIlvl" scope="col" class="py-3 px-6 cursor-pointer">
                      ilvl
                    </th>
                    <th @click="sortRio" scope="col" class="py-3 px-6 cursor-pointer">
                      Mythic score
                    </th>
                    <th @click="sortRaidVault" scope="col" class="py-3 px-6 cursor-pointer">
                      Raid vault
                    </th>
                    <th @click="sortDungeonVault" scope="col" class="py-3 px-6 cursor-pointer">
                      Dungeon vault
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <playertable @sortableData="sortableData" :player="player.character" v-for="player in lol"
                    :key="player.character.id" />
                </tbody>
              </table>
            </div>
          </div>
          <p class="text-black dark:text-white" v-if="table === 'wish' && !wishLoaded">
            I'm loading..
          </p>
          <div v-if="table === 'wish' && wishLoaded">
            <button @click="showRaids = !showRaids" id="dropdownDefault" data-dropdown-toggle="dropdown"
              class="mb-5 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              type="button">{{raids.filter(x => x.id == $store.state.raid )[0].name}} <svg class="ml-2 w-4 h-4"
                aria-hidden="true" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
              </svg></button>
            <!-- Dropdown menu -->
            <div v-if="showRaids" id="dropdown"
              class="z-10 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 absolute">
              <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
                <li v-for="raid in raids"
                  @click="$store.dispatch('changeRaid', raid.id); wishLoaded = false;showRaids = false">
                  <a href="#"
                    class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">{{raid.name}}</a>
                </li>
              </ul>
            </div>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
              <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 overflow-scroll">
                <thead
                  class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                  <tr>
                    <th scope="col" class="py-3 px-6">
                      Name
                    </th>
                    <th v-for="boss in $store.state.wishes" scope="col" class="py-3 px-6">
                      {{ boss.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <wishlist :player="player.character" v-for="player in lol" />
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="table === false" class="grid gap-8 mb-6 lg:mb-16 md:grid-cols-2">
            <player :player="player.character" v-for="player in lol" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import player from './components/player.vue'
import playertable from './components/table.vue'
import wishlist from './components/wishlist.vue'
import settings from './components/settings.vue'
export default {
  name: 'App',
  components: {
    player,
    settings,
    wishlist,
    playertable
  },
  data() {
    return {
      lol: null,
      table: true,
      showRaids: false,
      dark: true,
      sortingSettings: {
        ilvl: true,
        rio: false,
        raid: false,
        dungeon: false,
        class: false,
        name: false,
      },
      raids: [{
        name: 'Vault of the Incarnates',
        id: 16
      }
      ],
      loaded: false,
      wishLoaded: false,
    }
  },
  methods: {
    changeThem() {
      this.dark = !this.dark;
      localStorage.theme = this.dark ? 'dark' : 'light'
      if(this.dark) {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    },
    sortableData(val) {
      this.lol[this.lol.findIndex(x => x.character.id == val.id)]["playerdata"] = val;
    },
    sortIlvl() {
      this.sortingSettings.ilvl = !this.sortingSettings.ilvl;
      this.lol.sort((a, b) => {
        return this.sortingSettings.ilvl ? a.playerdata.ilvl - b.playerdata.ilvl : b.playerdata.ilvl - a.playerdata.ilvl;
      });
    },
    sortRio() {
      this.sortingSettings.rio = !this.sortingSettings.rio;
      this.lol.sort((a, b) => {
        return this.sortingSettings.rio ? a.playerdata.rating.rating - b.playerdata.rating.rating : b.playerdata.rating.rating - a.playerdata.rating.rating;
      });
    },
    sortClass() {
      this.sortingSettings.class = !this.sortingSettings.class;
      this.lol.sort((a, b) => {
        return this.sortingSettings.class ? a.playerdata.class.localeCompare(b.playerdata.class) : b.playerdata.class.localeCompare(a.playerdata.class);
      });
    },
    byName() {
      this.sortingSettings.name = !this.sortingSettings.name;
      this.lol.sort((a, b) => {
        return this.sortingSettings.name ? a.character.name.localeCompare(b.character.name) : b.character.name.localeCompare(a.character.name);
      });
    },
    sortRaidVault() {
      this.sortingSettings.raid = !this.sortingSettings.raid;
      this.lol.sort((a, b) => {
        return this.sortingSettings.raid ? a.playerdata.vault.raids.reduce((partialSum, a) => partialSum + a, 0) - b.playerdata.vault.raids.reduce((partialSum, a) => partialSum + a, 0) : b.playerdata.vault.raids.reduce((partialSum, a) => partialSum + a, 0) - a.playerdata.vault.raids.reduce((partialSum, a) => partialSum + a, 0);
      });
    },
    sortDungeonVault() {
      this.sortingSettings.dungeon = !this.sortingSettings.dungeon;
      this.lol.sort((a, b) => {
        return this.sortingSettings.dungeon ? a.playerdata.vault.mythic.reduce((partialSum, a) => partialSum + a, 0) - b.playerdata.vault.mythic.reduce((partialSum, a) => partialSum + a, 0) : b.playerdata.vault.mythic.reduce((partialSum, a) => partialSum + a, 0) - a.playerdata.vault.mythic.reduce((partialSum, a) => partialSum + a, 0);
      });
    }
  },
  created() {
    if (window.location.pathname != '/') {
      switch (window.location.pathname) {
        case '/wishlist':
          this.table = 'wish'
          break;
        case '/cards':
          this.table = false
          break;
        case '/table':
          this.table = true
          break;
      }
    }
  },
  mounted() {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
      document.documentElement.classList.add('dark')
      this.dark = true;
    } else {
      document.documentElement.classList.remove('dark')
      this.dark = false;
    }


    this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'GOT_GUILD':
          this.lol = this.$store.getters.getRaiders;
          this.$store.dispatch('GET_VAULTS');
          this.$store.dispatch('GET_WISHLIST');
          break;
        case 'GET_VAULTS':
          this.loaded = true;
          break;
        case 'GET_WISHLIST':
          this.wishLoaded = true;
          break;
      }
    })
    this.$store.dispatch('GET_GUILD');
  }
}
</script>

<style>
</style>
