import { createApp } from 'vue'

import {store} from './store'
import App from './App.vue'
import './style.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BlizzAPI } from "blizzapi";
const wowapi = new BlizzAPI({
  region: "eu",
  clientId: "3ab940186ee54eae99aa90521e43eea9",
  clientSecret: "XzohCc98o8zqUv5yS8AO7kSc4WtgajLG",
});
createApp(App).use(VueAxios, axios).use(wowapi).use(store).mount('#app')