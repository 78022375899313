// Create a new store instance.
import { createStore } from "vuex";
import axios from "axios";
import "node-fetch";
import { BlizzAPI } from 'blizzapi'
const wowapi = new BlizzAPI({
  region: "eu",
  clientId: "3ab940186ee54eae99aa90521e43eea9",
  clientSecret: "XzohCc98o8zqUv5yS8AO7kSc4WtgajLG",
});

export const store = createStore({
  state() {
    return {
      guild: [],
      wowaudit: [],
      wishes: [],
      raid: 16,
    };
  },
  mutations: {
    GOT_GUILD(state, val) {
      state.guild = val;
    },
    GET_VAULTS(state, val) {
      state.wowaudit = val;
    },
    GET_WISHLIST(state, val) {
      val.forEach(boss => {
        let maxupgradeP = 0;
        boss.items.forEach(item => {
          item.wishlists.forEach(wishlist => {
            wishlist.wishes.forEach(wish => {
              if(wish.percentage > maxupgradeP) {
                maxupgradeP = wish.percentage
                boss["maxupgrade"] = wish.percentage
              }
            })
          })
        })
      })
      state.wishes = val;
    },
  },
  actions: {
    GET_GUILD(context) {
      wowapi
        .query(
          "/data/wow/guild/ravencrest/explicit/roster?namespace=profile-eu&locale=en_GB"
        )
        .then((res) => {
             context.commit("GOT_GUILD", res);
        });
    },
    changeRaid(context, val) {
      this.state.raid = val
      context.dispatch('GET_WISHLIST')
    },
    GET_VAULTS(context) {
      axios
        .get(
          "https://data.wowaudit.com/dragonflight/ef5c69d0fd5753d0f3bf0bd9557f5f86.json"
        )
        .then((res) => {
          context.commit("GET_VAULTS", res.data);
        });
    },
    GET_WISHLIST(context) {
      fetch("https://scrapeninja.p.rapidapi.com/scrape", {
        headers: {
          accept: "application/json, text/plain, */*",
          "accept-language": "en-US,en;q=0.9,da-DK;q=0.8,da;q=0.7",
          "cache-control": "no-cache",
          "content-type": "application/json",
          expires: "0",
          pragma: "no-cache",
          "sec-ch-ua":
            '"Chromium";v="104", " Not A;Brand";v="99", "Google Chrome";v="104"',
          "sec-ch-ua-mobile": "?0",
          "sec-ch-ua-platform": '"Windows"',
          "sec-fetch-dest": "empty",
          "sec-fetch-mode": "cors",
          "sec-fetch-site": "same-site",
          usequerystring: "true",
          "x-rapidapi-host": "scrapeninja.p.rapidapi.com",
          "x-rapidapi-key":
            "f95213ddf6msh6d54bf6060f53c8p115271jsn6bc7f897d062",
          "x-rapidapi-ua": "RapidAPI-Playground",
        },
        referrer: "https://rapidapi.com/",
        referrerPolicy: "strict-origin-when-cross-origin",
        body:
          '{"url":"https://wowaudit.com/api/guilds/34322/teams/40053/loot_wishlist?difficulty=mythic&amp;instance=' +
          this.state.raid +
          '","method":"GET","retryNum":1,"geo":"eu","headers":["accept: application/json, text/plain, */*","accept-encoding: gzip, deflate, br","accept-language: en-US,en;q=0.9,da-DK;q=0.8,da;q=0.7","cookie: user_id=noS9Z6zxjVSXhmiKYVA9edi2; CSRF-TOKEN=pFKDJO8v2Z4rs8ApmN5BFEfQhqsuggvG06%2BekhCJ9LZfKR6suMt2yxMAHcG1t3kFMk4z9JNPmoesMl4FktD%2FBQ%3D%3D; _session_id=yxTqo0eXeYXfYQvD%2FM7ot9mJC9t%2FH%2FpXJMrAu9bs7C73MwvlsSQ8dpHWSwzmX8fqeQOxIcEoSQproqvPpsfhiiGfb5ARacTRot9%2FAOR38uq1MvGLqhfNCXOCaAHBp51ViOgTMZROjCbWn%2F5oOwU%3D--HAtti18i3fYS1Uz3--fsGqS%2FcLcdNuRRT49X2y7Q%3D%3D","dnt: 1","if-none-match: W/\\"2dd91ecac43f953f9fe9d927c98e236c\\"","referer: https://wowaudit.com/eu/ravencrest/explicit/explicit/wishlists/overview","sec-ch-ua: \\"Chromium\\";v=\\"104\\", \\" Not A;Brand\\";v=\\"99\\", \\"Google Chrome\\";v=\\"104\\"","sec-ch-ua-mobile: ?0","sec-ch-ua-platform: \\"Windows\\"","sec-fetch-dest: empty","sec-fetch-mode: cors","sec-fetch-site: same-origin","user-agent: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.0.0 Safari/537.36","x-csrf-token: pFKDJO8v2Z4rs8ApmN5BFEfQhqsuggvG06+ekhCJ9LZfKR6suMt2yxMAHcG1t3kFMk4z9JNPmoesMl4FktD/BQ=="]}',
        method: "POST",
        mode: "cors",
        credentials: "omit",
      })
        .then((response) => response.json())
        .then((data) => {
          context.commit("GET_WISHLIST", JSON.parse(data.body).encounters);
        });
    },
  },
  getters: {
    getRaiders(state) {
      return state.guild.members.filter(
        (x) =>
          x.rank === 3 ||
          x.rank === 4 ||
          x.character.name == "Mctoffees" ||
          x.character.name == "Diefwar" ||
          x.character.name == "Heidson" ||
          x.character.name == "Pawnium"
      );
    },
    getVault: (state) => (player) => {
      let vault = {};
      state.wowaudit.forEach((data) => {
        if (data[0] == player) {
          vault = {
            raids: [data[174], data[175], data[176]],
            mythic: [data[177], data[178], data[179]],
          };
        }
      });
      return vault;
    },
  },
});
