<template>
    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-900">
        <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
            <span :style="{ color: classColor }">{{ player.name }}</span>
        </th>
        <td v-for="(boss, index) in bosses" class="py-4 px-6">
            <span class="text-gray-500 dark:text-gray-400 mr-2 flex">
                <template v-for="wish in wishes">
                    <p v-if="parseInt(wish.bossIndex) == parseInt(index)"
                        :class="['relative px-2 py-2 rounded text-white mr-2 ', getColorValue(index, wish)]">
                        <span v-if="!wish.item.val"
                            class="-top-4 -left-5 absolute shadow-lg bg-red-500 text-xs rounded py-1 px-2">Manual</span>
                        <span class="font-bold">{{ wish.item.type }}</span><span
                            v-if="(typeof wish.item.val != 'undefined')"
                            class="px-1 ml-1 rounded-sm bg-purple-500 font-bold text-xs">{{ parseFloat(wish.item.val).toFixed(2) + '%' }}</span><br />

                        <span>
                            {{ wish.item.name }}
                        </span>
                    </p>
                </template>

            </span>
        </td>

    </tr>
</template>
<script>
export default {
    name: 'playertable',
    props: {
        player: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            wowauditId: 0,
            loaded: false,
            bosses: [],
            wishes: [],
            highestPercentUpgrade: 0
        }
    },
    created() {
        this.getPlayerId();
        this.$store.state.wishes.forEach(boss => {
            this.bosses.push(boss.name)
        })
        this.getPlayerInfo();

        this.calculateColor()
    },
    methods: {
        calculateColor() {
            this.$store.state.wishes.forEach(wish => {
                wish.items.forEach(item => {
                    item.wishlists.forEach(wishlist => {
                        wishlist.wishes.forEach(wi => {
                            if (wi.percentage > this.highestPercentUpgrade) {
                                this.highestPercentUpgrade = wi.percentage
                            }
                        })
                    })
                })
            })
        },
        getPlayerId() {
            this.$store.state.wowaudit.forEach(data => {
                if (data[0] == this.player.name) {
                    this.wowauditId = data[133]
                }
            })
        },
        getColorValue(boss, wish) {
            let realval = this.$store.state.wishes[boss].maxupgrade / this.$store.getters.getRaiders.length;
            if (wish.item.val <= realval) {
                return 'bg-red-500'
            } else if (wish.item.val > realval && wish.item.val < (this.$store.state.wishes[boss].maxupgrade - realval)) {
                return 'bg-yellow-400'
            } else if (wish.item.val > (realval * 2)) {
                return 'bg-green-500'
            } else if (typeof wish.item.val == 'undefined') {
                switch (wish.item.manual) {
                    case 0:
                        return 'bg-red-500'
                    case 50:
                        return 'bg-yellow-400'
                    case 100:
                        return 'bg-green-500'
                }
            }
        },
        getPlayerInfo() {
            this.$store.state.wishes.forEach(boss => {
                boss.items.forEach(item => {
                    if (item.wishlists.length > 0) {
                        item.wishlists.forEach(wish => {
                            if (this.wowauditId == wish.character_id) {
                                this.wishes.push({ item: { name: item.name, type: item.slot, val: wish.wishes[0].percentage, manual: wish.wishes[0].weight_percentage }, bossIndex: this.bosses.findIndex(x => x == boss.name) })
                            }
                        })
                    }
                })
            })
        },

    },
    computed: {
        classColor() {
            switch (this.player.playable_class.id) {
                case 1:
                    return '#C69B6D'
                    break;
                case 2:
                    return '#F48CBA'
                    break;
                case 3:
                    return '#AAD372'
                    break;
                case 4:
                    return '#00FF98'
                    break;
                case 5:
                    return window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000'
                    break;
                case 6:
                    return '#C41E3A'
                    break;
                case 7:
                    return '#0070DD'
                    break;
                case 8:
                    return '#3FC7EB'
                    break;
                case 9:
                    return '#8788EE'
                    break;
                case 10:
                    return '#00FF98'
                    break;
                case 11:
                    return '#FF7C0A'
                    break;
                case 12:
                    return '#A330C9'
                    break;
            }
        }
    }
}
</script>