<template>
    <div v-if="loaded" class="items-center bg-gray-50 rounded-lg shadow sm:flex dark:bg-gray-800 dark:border-gray-700">
        <div class="rounded-lg rounded-l-lg rounded-r-none h-full w-[217px] !bg-cover !bg-center hidden sm:block"
            :style="{ 'background': 'url(' + image + ')' }"></div>
        <div class="p-5">
            <h3 :style="{ color: classColor }" class="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                {{ player.name }}
            </h3><span class="mr-2 font-bold text-purple-500">{{ ilvl }}</span>
            <span class="text-gray-500 dark:text-gray-400 mr-2">{{ spec }}</span><span :style="{ color: classColor }">{{
                class}}</span>
            <p class="my-2">
            <p class="font-bold text-gray-600 w-full dark:text-white">Mythic score:</p> <span class="font-bold"
                :style="{ 'color': 'rgba(' + rating?.color?.r + ',' + rating?.color?.g + ',' + rating?.color?.b + ',' + rating?.color?.a +')' }">{{
                parseInt(rating.rating) }}</span>
            </p>
            <p class="my-2">
                <span class="font-bold text-gray-600 dark:text-white">Raid vault:</span> <span
                    :class="['text-white mx-1 bg-orange-500 px-3 text-xs font-bold py-1 rounded', {'opacity-40': vault == 0}]"
                    v-for="vault in vault.raids">{{vault == 0 ? 'None' : vault}}</span>
            </p>
            <p class="my-2">
                <span class="font-bold text-gray-600 dark:text-white">Dungeon vault:</span> <span
                    :class="['text-white mx-1 bg-purple-500 px-3 text-xs font-bold py-1 rounded', {'opacity-40': vault == 0}]"
                    v-for="vault in vault.mythic">{{vault == 0 ? 'None' : vault}}</span>
            </p>
        </div>
    </div>
</template>


<script>
import { BlizzAPI } from "blizzapi";
const wowapi = new BlizzAPI({
    region: "eu",
    clientId: "3ab940186ee54eae99aa90521e43eea9",
    clientSecret: "XzohCc98o8zqUv5yS8AO7kSc4WtgajLG",
});
export default {
    name: 'player',
    props: {
        player: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            class: '',
            spec: '',
            ilvl: 0,
            image: '',
            rating: 0,
            vault: [],
            loaded: false,
        }
    },
    created() {
        this.getPlayerInfo()
    },
    methods: {
         async getPlayerInfo() {
            let profile = await wowapi.query(`/profile/wow/character/${this.player.realm.slug}/${this.player.name.toLowerCase()}?namespace=profile-eu&locale=en_GB`)
            let char = await wowapi.query(`/profile/wow/character/${this.player.realm.slug}/${this.player.name.toLowerCase()}/character-media?namespace=profile-eu&locale=en_GB`)
            let myth = await wowapi.query(`/profile/wow/character/${this.player.realm.slug}/${this.player.name.toLowerCase()}/mythic-keystone-profile?namespace=profile-eu&locale=en_GB`)
            this.class = profile.character_class.name;
            this.spec = profile.active_spec.name
            this.ilvl = profile.equipped_item_level
            this.image = char.assets[2].value
            this.rating = myth.current_mythic_rating
            await this.getVault();
            this.$emit('sortableData', { id: this.player.id, ilvl: this.ilvl, class: this.class, spec: this.spec, rating: this.rating, vault: this.vault })
            this.loaded = true;
        },
        getVault() {
            this.$store.state.wowaudit.forEach(data => {
                if (data[0] == this.player.name) {
                    let Raidvault1 = data[174] == "" ? 0 : data[174];
                    let Raidvault2 = data[175] == "" ? 0 : data[175];
                    let Raidvault3 = data[176] == "" ? 0 : data[176];
                    let Mythicvault1 = data[177] == "" ? 0 : data[177];
                    let Mythicvault2 = data[178] == "" ? 0 : data[178];
                    let Mythicvault3 = data[179] == "" ? 0 : data[179];
                    this.vault = { raids: [Raidvault1, Raidvault2, Raidvault3], mythic: [Mythicvault1, Mythicvault2, Mythicvault3] }
                }
            })
        },
    },
    computed: {
        classColor() {
            switch (this.player.playable_class.id) {
                case 1:
                    return '#C69B6D'
                    break;
                case 2:
                    return '#F48CBA'
                    break;
                case 3:
                    return '#AAD372'
                    break;
                case 4:
                    return '#00FF98'
                    break;
                case 5:
                    return window.matchMedia('(prefers-color-scheme: dark)').matches ? '#fff' : '#000'
                    break;
                case 6:
                    return '#C41E3A'
                    break;
                case 7:
                    return '#0070DD'
                    break;
                case 8:
                    return '#3FC7EB'
                    break;
                case 9:
                    return '#8788EE'
                    break;
                case 10:
                    return '#00FF98'
                    break;
                case 11:
                    return '#FF7C0A'
                    break;
                case 12:
                    return '#A330C9'
                    break;
            }
        }
    }
}
</script>